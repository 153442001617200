<template>
  <div class="login-page page-top">
		<headerBar :title="i18n.t('register.title')" :showArrow="true" @back="back"></headerBar>

		<div class="login-form small thin">
			<div class="form-item">
				<div class="label require">{{i18n.t('register.label1')}}</div>
				<div class="input">
					<input type="text" v-model="form.username"  @input="handleInput" :placeholder="i18n.t('register.placeholder1')">
				</div>
			</div>
      <div class="form-item" v-show="register_remark">
        <div class="label require">{{i18n.t('register.label5')}}</div>
        <div class="input">
          <input type="text" v-model="form.Nickname" :placeholder="i18n.t('register.placeholder5')">
        </div>
      </div>
			<div class="form-item">
				<div class="label require">{{i18n.t('register.label2')}}</div>
				<div class="input">
					<input type="text" v-model="form.password" :placeholder="i18n.t('register.placeholder2')">
				</div>
			</div>
			<div class="form-item">
				<div class="label require">{{i18n.t('register.label4')}}</div>
				<div class="input">
					<input type="text" v-model="form.safePassword" :placeholder="i18n.t('register.placeholder4')">
				</div>
			</div>
      <div class="form-item">
        <div class="label require">{{i18n.t('register.label3')}}</div>
        <div class="input">
          <input type="text" v-model="form.invite" :placeholder="i18n.t('register.placeholder3')">
        </div>
      </div>
			<div class="form-item  desc">
        
        <div>
          <Checkbox v-model="checked" shape="square">
						<div>
							<span>{{i18n.t('register.role1')}}</span>
							<span>{{i18n.t('register.role2')}}</span>
							<span>{{i18n.t('register.role3')}}</span>
						</div>
					</Checkbox>
        </div>
      </div>

			<div class="btn-box mt-30">
        <div class="btn-line flex-center">
          <Button type="info" class="flex-1 blue-btn" @click="submit">{{i18n.t('register.btn')}}</Button>
        </div>
      </div>


			<div class="bottom-desc bottom">
				<span>{{i18n.t('register.account')}}</span>
				<span class="green" @click="toLogin">{{i18n.t('register.login')}}</span>
			</div>
		</div>
  </div>
</template>
<script>
import { NavBar, Button , Checkbox,Dialog } from 'vant';
import {login, register} from '@/api/login'
import headerBar from '@/components/header'
import {setToken, setUser, setUserId} from "@/utils/auth";
import {customization, userInfo} from "@/api/user";
const FingerprintJS = require('@fingerprintjs/fingerprintjs')

// Initialize the agent at application startup.
const fpPromise = FingerprintJS.load()
export default {
  components: {
    NavBar,
    Button,
		Checkbox,
    headerBar,
    Dialog,
  },
  data() {
    return {
      register_remark: false,
      form: {
        username: '',
        password: '',
        safePassword: '',
        Fingerprint: '',
        Nickname: '',
        invite: ''
      },
			checked: false,
    }
  },
  created() {
    customization().then(res => {
      this.register_remark = res.data.register_remark
    })
  },
  mounted() {
    let code = this.$route.query.code
    if (code) {
      this.form.invite = code
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: 'login'
      })
    },
		toLogin() {
			this.back()
		},
    handleInput() {
      // 过滤输入框中的空格，并重新赋值给表单数据
      this.form.username = this.form.username.replace(/\s+/g, '');
    },
    handleKeydown(event) {
      // 检查是否按下空格键，如果是则阻止默认行为
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    submit() {

      if (!this.form.username) {
        this.$toast({
          message: this.i18n.t('register.placeholder1'),
          icon: 'warning-o'
        })
        return
      }
      if (!this.form.password) {
        this.$toast({
          message: this.i18n.t('register.placeholder2'),
          icon: 'warning-o'
        })
        return
      }
      // if (!this.form.invite) {
      //   this.$toast({
      //     message: this.i18n.t('register.placeholder3'),
      //     icon: 'warning-o'
      //   })
      //   return
      // }
      if (!this.form.safePassword) {
        this.$toast({
          message: this.i18n.t('register.placeholder4'),
          icon: 'warning-o'
        })
        return
      }
      
      let form = new FormData()
      form.append('user_name', this.form.username)
      form.append('password', this.form.password)
      form.append('invite', this.form.invite)
      form.append('Nickname', this.form.Nickname)
      form.append('SafePassword', this.form.safePassword)
      register(form).then(res => {
        if (res.code == 0) {
          // this.$toast.success(this.i18n.t('register.success'))
          let form = new FormData()
          form.append('user_name', this.form.username)
          form.append('password', this.form.password)
          form.append('Fingerprint', this.form.Fingerprint)
          form.append('token', true)
          login(form).then(res => {
            if (res.code == 0) {
              this.$toast.success(this.i18n.t('login.success'))
              setToken(res.data.token)
              setUser(this.form.username)
              setUserId(res.data.id)
              localStorage.setItem('username', this.form.username)
              if (this.checked) {
                localStorage.setItem('password', this.form.password)
              }
              // 注册弹出窗口
              if(this.$store.state.pop_window === "true") {
                let text = this.i18n.t("register.desc") + "\n"
                text += this.i18n.t("register.desc1") + "\n"
                text += this.i18n.t("register.desc2") + "\n"
                text += this.i18n.t("register.desc3") + "\n"
                text += this.i18n.t("register.desc4") + "\n"
                text += this.i18n.t("register.desc5") + "\n"
                text += this.i18n.t("register.desc6") + "\n"
                let title = this.i18n.t("register.desc_title")
                let ensure = this.i18n.t("register.btn1")
                // Inshallah|Shop
                // 使用 replace() 方法来替换文本
                // appName
                title = title.replaceAll("$1", this.$store.state.appName);
                text = text.replaceAll("$1", this.$store.state.appName);
                Dialog.alert({
                  title: title,
                  message: text,
                  messageAlign: 'left',
                  confirmButtonText: ensure,
                }).then(() => {
                  // on close
                  this.$router.push({
                    name: 'index'
                  })
                });
              } else {
                this.$router.push({
                  name: 'index'
                })
              }

            } else {
              this.$toast({
                message: res.msg,
                icon: 'warning-o'
              })
            }
          })
        } else {
          this.$toast({
            message: res.msg,
            icon: 'warning-o'
          })
        }
      })
    },
  }
}
</script>
